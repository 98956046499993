import ProgramOfDaySection from "./sections/ProgramOfDaySection";
import ConfirmAttendanceSection from "./sections/ConfirmAttendanceSection";
import GuestsInvitationSection from "./sections/GuestsInvitationSection";
import './style/invitation-1.scss';
import {motion} from "framer-motion";
import {useEffect, useState} from "react";
import {DateFormat, formatEventMonth, formatEventYear, SECTIONS} from "./helper/Helper";
import CountdownSection from "./sections/CountdownSection";
import InvitationPopup from "../main/invitation-popup/invitationPopup";
import Loading from "../../../custom components/loading/Loading";
import {useLoading} from "../../../../main/providers/LoadingProvider";
import EventParticipants from "./sections/EventParticipants";

function Invitation1() {

    const eventDay = 14;
    const eventMonth = 'December';
    const eventYear = 2024;

    // const { showLoading, hideLoading } = useLoading();

    useEffect(() => {
        const timeoutDuration = 3000;

        // showLoading();

        const timeoutId = setTimeout(() => {
            // hideLoading();
        }, timeoutDuration);

        return () => clearTimeout(timeoutId);
    }, []);

    useEffect(() => {
        const scrollToTop = () => {
            window.scrollTo({ top: 0, behavior: "smooth" });
        };

        const timer = setTimeout(scrollToTop, 100);
        return () => clearTimeout(timer);
    }, []);

    function scrollToSection() {
        const section = document.getElementById(SECTIONS.guestInvitationSection);
        section.scrollIntoView({ behavior: "smooth" });
    }

    function closePopupHandler() {
        setIsPopupOpened(false)
    }

    const [isPopupOpened, setIsPopupOpened] = useState(false)

    return (
        <motion.section
            className="invitation-1"
            initial={{ opacity: 0, filter: "blur(10px)" }}
            animate={{ opacity: 1, filter: "blur(0)" }}
            transition={{ duration: 1.5 }}
        >
            {/*<button style={{textAlign: "center"}} onClick={() => { setIsPopupOpened(true) }}>Open Popup</button>*/}
            <InvitationPopup isPopupOpened={isPopupOpened} closePopupHandler={closePopupHandler}/>
            <CountdownSection id={SECTIONS.countdownSection}
                              scrollToSection={scrollToSection}
                              eventDay={eventDay}
                              eventMonth={eventMonth}
                              eventYear={eventYear}/>
            <GuestsInvitationSection
                id={SECTIONS.guestInvitationSection}
                eventDay={eventDay}
                eventMonth={formatEventMonth(eventMonth, DateFormat.NUMERIC)}
                eventYear={formatEventYear(eventYear, DateFormat.NUMERIC)}
            />
            <ProgramOfDaySection id={SECTIONS.programOfDaySection}/>
            {/*<ConfirmAttendanceSection id={SECTIONS.confirmAttendanceSection}/>*/}
            <EventParticipants id={SECTIONS.eventParticipants}/>

            <p className={"waiting-for-you"}>Waiting for You!</p>
        </motion.section>
    )
}

export default Invitation1