import {useLocation} from "react-router-dom";

export const PageChecker = () => {
    const location = useLocation();

    const isMatchingRoute = (routeName) => {
        const servicesPaths = [
            `${routeName}`,
            `/en${routeName}`,
            `/ru${routeName}`
        ];

        return servicesPaths.includes(location.pathname);
    };

    return { isMatchingRoute }
};