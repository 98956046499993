import React, {createContext, useState, useEffect, useContext} from "react";
import {SCREEN_SIZES} from "../constants/SCREEN_SIZES";

const ScreenSizeContext = createContext(null);

export const ScreenSizeProvider = ({children}) => {

    const getScreenSize = () => {
        const width = window.innerWidth;
        if (width >= SCREEN_SIZES.DESKTOP_L.value) return SCREEN_SIZES.DESKTOP_L;
        if (width >= SCREEN_SIZES.DESKTOP_M.value) return SCREEN_SIZES.DESKTOP_M;
        if (width >= SCREEN_SIZES.DESKTOP_S.value) return SCREEN_SIZES.DESKTOP_S;
        if (width >= SCREEN_SIZES.TABLET_M.value) return SCREEN_SIZES.TABLET_M;
        if (width >= SCREEN_SIZES.MOBILE_L.value) return SCREEN_SIZES.MOBILE_L;
        if (width >= SCREEN_SIZES.MOBILE_M.value) return SCREEN_SIZES.MOBILE_M;

        return SCREEN_SIZES.MOBILE_S;
    };

    const [screenSize, setScreenSize] = useState(getScreenSize());

    const isLessThan = (size) => {
        return window.innerWidth <= size.value;
    };

    useEffect(() => {
        const handleResize = () => {
            setScreenSize(getScreenSize());
        };

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <ScreenSizeContext.Provider value={{ isSmallerThan: isLessThan, screenSize }}>
            {children}
        </ScreenSizeContext.Provider>
    );
};

export const useScreenSize = () => {
    return useContext(ScreenSizeContext);
};
