export const INVITATION_IMAGES = {
    spouseImage1: '/assets/invitations/invitation-1/spouse_image_1.webp',
    spouseImage2: '/assets/invitations/invitation-1/spouse_image_2.webp',
    invitationWeddingCeremony: '/assets/invitations/invitation-1/wedding_ceremony.svg',
    invitationWeddingReception: '/assets/invitations/invitation-1/wedding_reception.svg'
}

export const DateFormat = {
    FULL: 'full',
    NUMERIC: 'numeric'
};

export const SECTIONS = {
    countdownSection: "countdown-section",
    guestInvitationSection: "guest-invitation-section",
    programOfDaySection: "program-of-day-section",
    confirmAttendanceSection :"confirm-attendance-section",
    eventParticipants :"event-participants-section",
}

export function formatEventMonth(month, formatType = DateFormat.FULL) {
    if (formatType === DateFormat.NUMERIC) {
        return `${new Date(`${month} 1, 2024`).getMonth() + 1}`;
    } else {
        return `${month}`;
    }
}

export function formatEventYear(year, formatType = DateFormat.FULL) {
    let formattedYear;

    if (formatType === DateFormat.NUMERIC) {
        formattedYear = year.toString().slice(-2);
    } else {
        formattedYear = year;
    }

    return `${formattedYear}`;
}