
export class GetCurrentCountry {

    async sendRequest() {
        try {
            const response = await fetch("https://ipinfo.io/json")

            if (!response.ok) {
                throw new Error(`Request failed with status ${response.status}`);
            }

            return response.json()
        } catch (error) {
            throw new Error(`Request failed with status ${error.message}`);
        }
    }
}