import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import './styles/main.css';
import reportWebVitals from './reportWebVitals';
import App from "./components/pages/App";
import './main/services/i18n';
import "react-responsive-carousel/lib/styles/carousel.min.css";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <App/>
);

reportWebVitals();
