function EventParticipants({ id }) {
    const data = [
        [
            "Norik",
            "Ashot",
            "Zarine",
            "Naira T.",
            "Barik",
            "Levon",
            "Zvart",
            "Manuk",
            "Gagik",
            "Tamara",
            "Rafayel",
            "Lianna",
        ],
        [
            "Elmira",
            "Khachik",
            "Armen Ch.",
            "Adel",
            "Gayane",
            "Mari",
            "Naira A.",
            "Vika",
            "Gor S.",
            "Samvel S.",
            "Ashot A.",
            "Susanna",
        ],
        [
            "Andranik",
            "Asya",
            "Harut",
            "Hripsime",
            "Hayk",
            "Mariam",
            "Monte",
            "Ani M.",
            "Aram H.",
            "Lyov",
            "Arman G.",
        ],
        [
            "Armen A.",
            "Anna A.",
            "Hovik",
            "Narine",
            "Tevos",
            "Hermine P.",
            "Nikal",
            "Artur F.",
            "Lilit",
            "Tigran",
            "Seda S.",
        ],
        [
            "David G.",
            "Anushik",
            "Avag",
            "Ani M.",
            "David S.",
            "Marina",
            "Vilik",
            "Syuzi",
            "Arsen",
            "Senik",
            "Artur Kh.",
            "Lukash",
        ],
        [
            "Gor H.",
            "Sargis",
            "Artash",
            "Martin",
            "Artyom",
            "Vahe",
            "Kristine",
            "Sirarpi",
            "Aksel",
            "Aram M.",
            "Satenik",
        ],
        [
            "Levon A.",
            "Olga",
            "Hayk A.",
            "Artur M.",
            "Lianna",
            "David M.",
            "Ani A.",
            "Arman T.",
            "Sona",
            "Levon P.",
            "Lyudmila",
            "Nver",
        ],
        [
            "Avetis",
            "Seda Kh.",
            "Arshak",
            "Lilit A.",
            "Elen",
            "Samvel Ch.",
            "Lilit H.",
            "Arayik",
            "Lianna",
            "Andranik B.",
            "Armine",
            "Gagik H.",
            "Hermine",
        ],
        [
            "Arsen",
            "Karine",
            "Narine",
            "Lianna",
            "Vardan",
            "Zara",
            "Armen",
            "Arman H.",
            "Armine",
            "Olga",
            "Avet",
        ],
        [
            "Manush",
            "Elza",
            "Artur",
            "Eleonora",
            "Aleta",
            "Ilich",
            "Karine",
            "Syomik",
            "Lyudmila",
            "Yura",
            "Aren",
            "Alina",
            "Artyom",
        ],
        [
            "Tsovinar",
            "Garik",
            "Kristina",
            "Arayik",
            "Nune",
            "Aram",
            "Arman B.",
            "Karine",
            "Ashot",
            "Anna",
            "Anna L.",
            "Levon",
            "Lianna",
        ],
        [
            "Siran",
            "Milana",
            "Anna G.",
            "Mher",
            "Knarik",
            "Artak I.",
            "Karine",
            "Artak D.",
            "Ashkhen",
            "Sirush",
            "Tigran",
            "Meri",
            "Mikayel",
        ],
        [
            "Vahan",
            "Erika",
            "Artur",
            "Ani",
            "Edita",
            "Vardges",
            "Asya",
            "Seda",
            "Zhanna",
            "Grigor",
            "Masha",
            "Yeva",
            "Ashot",
        ],
        [
            "Yeva T.",
            "David",
            "Anna H.",
            "Karine",
            "Armine D.",
            "Grenik",
            "Alisa",
            "Gevorg",
            "Rita",
            "Lilit",
            "Lusine",
        ],
    ];

    return (
        <section id={id}>
            <h1 className={"participants-section-title"}>Event Participants</h1>

            <div className={"participants"}>
                {data.map((tableData, index) => (
                    <ParticipantsTable key={index} tableNumber={index + 1} participants={tableData} />
                ))}
            </div>
        </section>
    );
}


function ParticipantsTable({ tableNumber, participants }) {
    return (
        <section className={"participants-table"}>
            <h1 className={"participants-table-number"}>Table N{tableNumber}</h1>

            <div className={"participants-names"}>
                {participants.map((participant, index) => (
                    <p key={index}>{participant}</p>
                ))}
            </div>
        </section>
    );
}

export default EventParticipants
