import { INVITATION_IMAGES } from "../helper/Helper";
import {AnimatingMotionDiv} from "../../../../custom components/MotionDiv";

function GuestsInvitationSection({ id, eventDay, eventMonth, eventYear }) {
    return (
        <section id={id}>
            <AnimatingMotionDiv
                id={`${id}-text`}
                className="guest-invitation-section-description"
                duration={1.2}
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.8 }}
            >
                <div className="guest-invitation-section-content">
                    <p className="guest-invitation-section-title">FRIENDS AND FAMILY</p>
                    <p className="guest-invitation-section-message">
                        You are warmly invited to participate in the celebration of the most important day of our life, our union in marriage.
                    </p>
                    <p className="guest-invitation-section-date">
                        {eventDay}․{eventMonth}․{eventYear}
                    </p>
                </div>
            </AnimatingMotionDiv>

            <AnimatingMotionDiv
                id={`${id}-images`}
                className="guest-invitation-section-images"
                duration={1.5}
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 1.2 }}
            >
                <div className="guest-invitation-section-top-image">
                    <img
                        src={INVITATION_IMAGES.spouseImage1}
                        className="guest-invitation-section-image"
                        alt="Spouse 1"
                    />
                </div>
                <div className="guest-invitation-section-bottom-image">
                    <img
                        src={INVITATION_IMAGES.spouseImage2}
                        className="guest-invitation-section-image"
                        alt="Spouse 2"
                    />
                </div>
            </AnimatingMotionDiv>
        </section>
    );
}

export default GuestsInvitationSection;
