import useViewportHeight from "../../../main/hooks/useViewportHeight";
import './styles/loading.scss'
import Lottie from "lottie-react";
import animationData from "../../../main/lottie/hyoor_loading.json";
import {BASE_IMAGES} from "../../../main/constants/BASE_IMAGES";
import {useLoading} from "../../../main/providers/LoadingProvider";

function Loading() {

    const { isLoading } = useLoading();
    const viewportHeight = useViewportHeight()

    if (!isLoading) return null;

    return (
        <>
            <section className={"loading"}>
                <Lottie animationData={animationData}  className={"lottie"}/>
            </section>
            <style jsx={"true"}>{`
                .loading {
                  height: ${viewportHeight};
                }
            `}</style>
        </>
    )
}

export default Loading