import {motion} from "framer-motion";
import React, {useEffect, useState} from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {observeIntersection} from "../../main/utility/utility";

export function MotionDiv({ id, className, duration = 1, content }) {

    const [isAppeared, setIsAppeared] = useState(false);

    useEffect(() => {
        const observer = observeIntersection(() => {
            setIsAppeared(true);
        }, () => {
            setIsAppeared(false);
        }, 0.3)

        const section = document.querySelector(`#${id}`);
        observer.observe(section);

        return () => {
            observer.unobserve(section);
        };
    }, []);

    return (
        <motion.div id={id}
                    initial={{opacity: 0, y: -20}}
                    animate={isAppeared ? {opacity: 1, y: 0} : {}}
                    exit={{opacity: 0, y: 20}}
                    transition={{duration: duration}}
                    className={className}>
            {content}
        </motion.div>
    )
}

export function AnimatingMotionDiv(
    {
        id,
        className,
        style,
        duration = 1,
        initial = { opacity: 0, y: -20 },
        animate = { opacity: 1, y: 0 },
        exit = { opacity: 0, y: 20 },
        children,
        threshold = 0.3
    }
) {
    const [isAppeared, setIsAppeared] = useState(false);

    useEffect(() => {
        const observer = observeIntersection(() => {
            setIsAppeared(true);
        }, () => {
            setIsAppeared(false);
        }, threshold);

        const section = document.querySelector(`#${id}`);
        if (section) {
            observer.observe(section);
        }

        return () => {
            if (section) {
                observer.unobserve(section);
            }
        };
    }, [id]);

    return (
        <motion.div
            id={id}
            initial={initial}
            animate={isAppeared ? animate : {}}
            exit={exit}
            transition={{ duration: duration }}
            className={className}
            style={style}
        >
            {children}
        </motion.div>
    );
}