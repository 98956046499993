import {NavLink} from "react-router-dom";
import {INVITATION_IMAGES} from "../helper/Helper";

function ProgramOfDaySection({ id }) {
    return (
        <section id="program-of-day-section">
            <h1 className="program-of-day-title">PROGRAM OF THE DAY</h1>

            <ProgramSection
                iconSrc={INVITATION_IMAGES.invitationWeddingCeremony}
                name="Wedding Ceremony"
                time="16:00"
                place="Saint Gevorg Church"
                address="Ararat Region, Marmarashen village, 36th Street"
                iconClass="wedding-ceremony-icon"
                navLink={"https://yandex.com/maps/?ll=44.470826%2C40.056587&mode=poi&poi%5Bpoint%5D=44.471054%2C40.056439&poi%5Buri%5D=ymapsbm1%3A%2F%2Forg%3Foid%3D58194433853&z=18.18"}
            />

            <ProgramSection
                iconSrc={INVITATION_IMAGES.invitationWeddingReception}
                name="Wedding Reception"
                time="17:30"
                place="Morena Restaurant Complex"
                address="Masis, Mkhitar Heratsi Street, 7/8"
                iconClass="wedding-reception-icon"
                navLink={"https://yandex.com/maps/org/222913353582/?ll=44.444778%2C40.059849&z=17"}
            />
        </section>
    );
}

function ProgramSection(
    {
        iconSrc,
        name,
        time,
        place,
        address,
        iconClass,
        navLink
    }
) {

    return (
        <div className="program-section-part">
            <div className="program-section-content">
                <img src={iconSrc} className={`program-icon ${iconClass}`} alt={`${name} icon`} />
                <p className="program-section-name">{name}</p>
                <p className="program-section-time">{time}</p>
                <p className="program-section-place">{place}</p>
                <p className="program-section-address">{address}</p>
            </div>
            <NavLink to={navLink} target="_blank" className="dark-button">How to get there</NavLink>
        </div>
    );
}

export default ProgramOfDaySection