import React, { useEffect } from 'react';
import {BASE_IMAGES} from "../../../main/constants/BASE_IMAGES";
import './styles/popup.css';

function Popup({ isOpen, onClose, width, height, closeIconColor, children }) {

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        return () => { document.body.style.overflow = 'auto' };
    }, [isOpen]);

    if (!isOpen) return null;

    return (
        <>
            <div className="popup-overlay" onClick={onClose}>
                <div className="popup-content" onClick={(e) => e.stopPropagation()}>
                    <button className="popup-close-btn" onClick={onClose}>
                        <img src={BASE_IMAGES.xIcon} className={"popup-close-icon"}/>
                    </button>
                    {children}
                </div>
            </div>
            <style jsx={"true"}>{`
              .popup-content {
                width: ${width && width} !important;
                height: ${height && height} !important;

                .popup-close-btn {
                  filter: ${closeIconColor} !important;
                }
              }
            `}</style>
        </>
    );
}

export default Popup;
