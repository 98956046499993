import {PageChecker} from "./pageChecker";

export function getGlobalStyle(variableName) {
  return typeof window === "undefined" ? "" : window
    .getComputedStyle(document.documentElement)
    .getPropertyValue(variableName)
    .trim();
}

export function observeIntersection(
    onEnter = () => {},
    onLeave = () => {},
    threshold = 0.5
) {
    let lastScrollY = window.scrollY; // Keeps track of the last scroll position

    return new IntersectionObserver(
        (entries) => {
            entries.forEach((entry) => {
                const currentScrollY = window.scrollY; // Current scroll position
                const isScrollingDown = currentScrollY < lastScrollY;

                if (entry.isIntersecting) {
                    onEnter();
                } else if (isScrollingDown) {
                    // Trigger onLeave only if scrolling down
                    onLeave();
                }

                lastScrollY = currentScrollY; // Update the last scroll position
            });
        },
        { threshold: threshold }
    );
}

export function scrollToSection(sectionName) {
    const element = document.getElementById(sectionName);
    if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: "center" });
    }
}

export function isMatchingRoute(routeName) {
    const pageChecker = PageChecker()
    return pageChecker.isMatchingRoute(routeName)
};

export function pxToNumber(pxString) {
    if (typeof pxString === 'string' && pxString.endsWith('px')) {
        return parseFloat(pxString.replace('px', '')); // Remove 'px' and convert to number
    }

    return Number(pxString);
}