import {useEffect, useState} from "react";

function useViewportHeight() {

    const [viewportHeight, setViewportHeight] = useState("100vh");

    useEffect(() => {
        setViewportHeight(`${window.innerHeight}px`);
    }, []);

    return viewportHeight;
}

export default useViewportHeight;