import CountdownTimer from "../../../../custom components/countdown_timer/CountdownTimer";
import {BASE_IMAGES} from "../../../../../main/constants/BASE_IMAGES";
import useViewportHeight from "../../../../../main/hooks/useViewportHeight";

function CountdownSection({ id, scrollToSection = () => {}, eventDay, eventMonth, eventYear }) {

    const eventDate = new Date(
        `${eventMonth} ${eventDay}, ${eventYear} 16:00:00`
    ).getTime()

    const viewportHeight = useViewportHeight()

    return (
        <section id={id} style={{height: viewportHeight}}>

            <div className={"countdown-section-content"}>
                <h1 className={"countdown-section-title"}>HRANT & KRISTINE</h1>
                <h2 className={"countdown-section-date"}>{eventMonth} {eventDay}, {eventYear}</h2>
                <p className={"countdown-section-time-countdown"}>Wedding day countdown!</p>

                <CountdownTimer eventDate={eventDate}/>
            </div>

            <button onClick={scrollToSection} className={"countdown-section-button"}>
                <img src={BASE_IMAGES.rightArrowIcon} className={"countdown-section-arrow"}/>
            </button>

        </section>
    )
}

export default CountdownSection