import {getGlobalStyle} from "../../../../../main/utility/utility";
import {useScreenSize} from "../../../../../main/providers/ScreenSizeProvider";
import {SCREEN_SIZES} from "../../../../../main/constants/SCREEN_SIZES";
import {BASE_IMAGES} from "../../../../../main/constants/BASE_IMAGES";
import Popup from "../../../../custom components/popup/Popup";
import './styles/invitation-popup.css';
import DateTimePickerView from "../../../../custom components/date-time-picker/DateTimePickerView";

function InvitationPopup({ isPopupOpened, closePopupHandler }) {

    const { isSmallerThan } = useScreenSize()

    return (
        <Popup isOpen={isPopupOpened}
               onClose={closePopupHandler}
               closeIconColor={getGlobalStyle(!isSmallerThan(SCREEN_SIZES.DESKTOP_S) && "--white-tint")}
               children={
                   <section className={"invitation-popup"}>
                       <h1 className={"invitation-popup-title"}>One Step Before Customizing</h1>
                       <div className={"invitation-popup-content"}>
                           <div className={"invitation-popup-input-container"}>
                               <p className={"invitation-popup-label"}>Invitation Link</p>
                               <div className="invitation-popup-url-input">
                                   <span className="url-prefix">hyoor.am/</span>
                                   <input
                                       className="invitation-popup-input"
                                       placeholder="your custom URL"
                                   />
                               </div>
                           </div>

                           <div className={"invitation-popup-input-container"}>
                               <p className={"invitation-popup-label"}>Event Start Date</p>
                               <DateTimePickerView/>
                           </div>

                           <button className={"invitation-popup-done-button"}>Let’s Begin</button>
                       </div>
                       <img src={BASE_IMAGES.customizingTemplateImage} className={"invitation-popup-image"}/>
                   </section>
               }
        />
    )
}

export default InvitationPopup