import {Outlet, useLocation} from "react-router-dom";
import {useEffect} from "react";
import i18n from "i18next";

function Language() {
    const location = useLocation()

    useEffect(() => {
        let language = location.pathname.split("/")[1]

        if (!["en", "ru"].includes(language)) {
            language = "am"
        }

        i18n.changeLanguage(language ? language : i18n.languages[0]).then()
    }, [location])

    return <Outlet/>
}

export default Language