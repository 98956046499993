import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import {useState} from "react";
import DateTimePicker from "react-datetime-picker";
import './styles/date-time-picker.css'

function DateTimePickerView() {

    const [value, onChange] = useState(new Date());

    return (
        <DateTimePicker
            onChange={onChange}
            value={value}
            disableClock
            disableCalendar
            clearIcon={null}
            minDate={new Date()}
            maxDate={new Date(2050, 0, 1)}
            format="dd-MM-y HH:mm"
        />
    );
}

export default DateTimePickerView